.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

:root {
  --ion-font-family: 'Open Sans';
  --ion-font-weigth: 800;
}


ion-content::part(scroll)::-webkit-scrollbar {
  display: none;
}

ion-content::part(scroll) {
  -ms-overflow-style: none; 
  scrollbar-width: none;
}

.spinner-circular {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}

.background-image {
  animation: breath 30s linear;
  animation-fill-mode: forwards;
  background: url(wewantwind.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
}

.map-custom-height {
  height: calc(100% - 130px);
}

.legend-custom-height {
  height: 130px;
}

@media only screen and (min-width: 600px) {

  .map-custom-height {
    height: calc(100% - 90px);
  }

  .legend-custom-height {
    height: 90px;
  }
    
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.maplibregl-popup,
.maplibregl-popup-content {
  pointer-events: none;
}

.popup-h1 {
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 120%;
  line-height: 120%;
}

.popup-p {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 150%;
}

.maplibregl-ctrl-group {
  background: unset!important;
}

.maplibregl-ctrl-group:not(:empty) {
  box-shadow: none!important;
}

.maplibregl-ctrl-top-left .maplibregl-ctrl {
  height: 50px!important;
}

.maplibregl-popup-tip {
  display:none;
}

.maplibregl-ctrl-top-left {
  left: 8px!important;
  top: 60px!important;
}

.maplibregl-ctrl-top-right {
  right: 8px!important;
}

.maplibregl-ctrl-spacer {
  height: 50px;
}

.item .item-content {
  background-color: transparent !important;
}

ion-menu::part(backdrop) {
  background-color: rgba(8, 8, 8, 0.5);
}

ion-menu::part(container) {
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.7);
}

.wewantwind-ctrl-group button {
  background-color: #ccc;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  outline: none;
  padding: 0;
  width: 50px;
  height: 50px;
  margin-bottom:5px;
  border-radius: 50%;  
  box-shadow: -5px -5px 10px #0000002b;
}

.planning-key-item {
  white-space: nowrap; 
  font-size: 60%;
  cursor: pointer;
}

.planning-key-footnote {
  font-size: 70%;
  padding-left: 10px;
  padding-bottom: 10px;
}

.instruction-text {
  padding-top: 10px;
  font-size:85%;
}

.checkbox-item {
  font-size: 80%;
}

.wrap {
  white-space: normal;
}

.modal-title {
  width: default;
  margin: auto;
}

/* .maplibregl-ctrl-scale {
  top: -60px !important;
  position: relative;
} */

.maplibregl-canvas {
  width: 100%!important;
  height: 100%!important;
}

.map-wrap {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
}

.key-image {
  width: 20px;
  padding-left: 7px;
  padding-right: 7px;
  margin-top: -2px;
}

.turbine-button {
  z-index:  1000;
  position: absolute;
  bottom:   30px;
  width:    auto;
}

.turbine-distance-bottom,
.turbine-distance {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 10px;
  padding-top: 13px;
  z-index: 2;
  font-size: 70%;
  font-weight: 400;
  width: 100%;
  background-color: #ffffff73;
}

.turbine-distance-bottom {
  bottom: 0px;
  height: 70px;
  font-size: 100%;
  /* padding: 30px; */
  padding-bottom: 30px;
  top: unset;
  text-align: center;
  color: white;
  background-color: #f89114;
}

.submap {
  width: 300px;
  height: 300px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  box-shadow: -10px -10px 25px gray;
}

.links-container {
  padding-right: 20px;
  padding-top: 10px;
}

.centred-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.horizontal-centred-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wewantwind-largetext {
  font-size: 400%;
  font-weight: 900;
  text-shadow: 0px 0px 7px rgb(89 88 88 / 61%);
}

.wewantwind-headertext {
  font-weight: 900;
  font-size:150%;
  text-decoration: none!important;
}

.wewantwind-bodytext {
  font-size: 120%;
  color: #fff;
  font-weight: 400;
  text-shadow: 0px 0px 7px rgb(89 88 88);
}

.wewantwind-infotab {
  padding: 10px;
  background: #ffffffcf;
  border-radius: 20px;
}

.wewantwind-link {
  text-decoration: none;
  cursor: pointer;
  margin:10px;
  color: white;
  text-shadow: 0px 0px 7px rgb(89 88 88);
}

.wewantwind-bodyarea {
  max-width: 450px; 
  text-align: center;
  margin: auto;
  padding-bottom: 20px;
}

.alert-title.sc-ion-alert-md {
  font-size: 1rem;
}

.alert-button-group.sc-ion-alert-md {
  padding: 0px;
}

.submap-centre {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  font-size: 800%;
}

.submap-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12px 0 0 -12px;
}

@keyframes breath {
  0%   {   transform: scale(1);}
  100% {   transform: scale(1.2);  }
}

@media only screen and (max-width: 600px) {

  .wob-toolbar {
    --min-height: 40px!important;
  }
  
  .planning-key-title {
    font-size: 70%;
  }

  .instruction-text {
    padding-top: 0px;
  }
  
  .planning-key-title-container {
    padding: 0px;
  }

  .close-icon-hide-mobile {
    display: none;
  }

  .planning-key-item {
    font-size: 70%;
  }

  .planning-key-footnote {
    font-size: 60%;
  }

  .add-instructions {
    font-size: 80%;
    text-align: left;
    line-height: normal;
  }  

  .submap {
    width: 100%;
    height: 45%;
  }

  .list-md {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .turbine-distance {
    padding: 10px;
    padding-top: 15px;
  }
  
  /* .turbine-button {
    bottom:   45%;
  } */
  
  .wewantwind-largetext {
    font-size: 200%;
  }
  
  .wewantwind-headertext {
    font-size:100%;
  }

  .wewantwind-bodyarea {
    width: 280px;
    padding-bottom: 10px;
  }
  
  .wewantwind-bodytext {
    font-size: 100%;
  }

  .wewantwind-link {
    font-size: 90%;
  }
 
  .links-container {
    padding-top: 3px;
  }    

  /* .maplibregl-ctrl-attrib {
    bottom: 200px;
  } */

  .maplibregl-ctrl button.maplibregl-ctrl-geolocate .maplibregl-ctrl-icon {
    width: 40px;
    height: 40px;
  }  

  .maplibregl-ctrl-top-left .maplibregl-ctrl {
    height: 40px!important;
  }
  
  .wewantwind-ctrl-group button {
    width: 40px;
    height: 40px;
    margin-bottom: 0px;
  }

  .turbine-distance-bottom {
    padding: 5px;
    height: 50px;
  }
}
